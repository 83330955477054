import React from "react";
import CaseStudyHeading from "./CaseStudiesHeading";
import CaseStudyProfile from "./CaseStudiesProfile";

// Sections
import Typography from "./DesignSystem/TypographyOnTheWeb";
import DesignProcess from "./DesignSystem/DesignProcess";
import Introduction from "./DesignSystem/Introduction";
import DesigningTheRoadmap from "./DesignSystem/DesigningTheRoadmap";
import DesigningContributionModel from "./DesignSystem/DesigningContribution";
import BuildOrBuy from "./DesignSystem/BuildOrBuy";
import FeelixDesignTokens from "./DesignSystem/FeelixDesignTokens";
import Airwallex from "./DesignSystem/Airwallex";
// import CustomerValueMap from "./DesignSystem/CustomerValueMap";

const CaseStudiesDesignSystems = () => {
  return (
    <React.Fragment>
      <CaseStudyHeading title="Design systems" />
      <CaseStudyProfile>
        <p>
          I craft design systems that teams actually want to use, systems that
          are reusable, composable, and scalable. By working closely with
          designers and developers, I ensure both Figma and coded components
          align from the start, embedding tokenisation, accessibility, and
          flexibility into the foundation.
        </p>
        <p>
          A great design system isn’t just documentation; it’s a living,
          evolving product that enables teams to build faster while maintaining
          consistency and quality. My focus is on reducing friction, increasing
          adoption, and helping teams create with confidence.
        </p>
      </CaseStudyProfile>
      {Introduction}
      {Airwallex}
      {DesigningTheRoadmap}
      {DesignProcess}
      {/* {CustomerValueMap} */}
      {BuildOrBuy}
      {FeelixDesignTokens}
      {DesigningContributionModel}
      {Typography}
    </React.Fragment>
  );
};

export default CaseStudiesDesignSystems;
