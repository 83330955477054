export const codeReminders = [
  {
    id: 1,
    title: "Inline If with Logical && Operator",
    description:
      "Below is an example of showing an element only if the property is defined on the component.",
    example: {
      comment: "// If this property is used, render this element. ",
      code: "{propNameHereUsed && (<div>{propNameHereUsed}</div>)}",
    },
  },
  {
    id: 2,
    title: "Inline If-Else with Conditional Operator",
    description:
      "Below is an example of how styled-components uses the condition.",
    example: {
      comment: "// If this condition, render true and then false.",
      code: 'twoColumns ? "1fr 1fr" : ""',
    },
  },
  {
    id: 3,
    title: "Exporting Data in React",
    description:
      "Separating data from the application and making it available to import",
    example: {
      comment: "",
      code: "export const codeReminders = [ ... ];",
    },
  },
  {
    id: 4,
    title: "Importing data in React",
    description: "",
    example: {
      comment: "",
      code: 'import { codeReminders } from "./data";',
    },
  },
  {
    id: 5,
    title: "Fixing React Route Refresh Issues on Netlify",
    description:
      "When deploying a React app with client-side routing, refreshing on a subpage (e.g., /about) can lead to a 404 error because the server doesn’t recognize the route. On Netlify, this can be fixed by adding a `_redirects` file with the rule:",
    example: {
      comment: "",
      code: "/*    /index.html   200",
    },
  },
];
