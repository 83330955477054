import React from "react";
import styled from "styled-components";
import CaseStudiesIntroduction from "../CaseStudiesIntroduction";
import CaseStudiesCard from "../CaseStudiesCard";
import ColumnLayout from "../ColumnLayout";
import Links from "../../../Links";
import EssentialsNew from "../../../../img/casestudy/design-systems/design-system-essentials-new.jpg";
import Rule from "../../../Rule";
import Heading from "../../../Heading";

// const TextEmphasis = styled.strong`
//   color: #dbdbdb;
// `;

const QuoteImage = styled.img`
  width: 100%;
  height: auto;
`;

const Quote = styled.div`
  background: #2f2f2f;
  border-radius: 22px;
  box-shadow: 0 34px 30px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const QuoteInner = styled.div`
  padding: var(--spacing-large);
  margin: 0;
  h4 {
    position: relative;
    line-height: 1.5;
    font-style: italic;
    font-family: serif;
    margin-top: 0;
    &:before {
      content: open-quote;
      font-family: "Gentium Book Basic", serif;
      position: absolute;
      left: -3rem;
      font-size: 4rem;
      color: #5d5d5d;
      font-weight: bold;
      top: -1.5rem;
    }
  }
`;

const CaseStudyLinks = styled(Links)`
  color: white;
  border-color: rgba(255, 255, 255, 0.2);
  &:hover {
    color: #ff73df;
  }
`;

const Introduction = (
  <CaseStudiesCard id="maturefeelix">
    <CaseStudiesIntroduction>
      <Heading variant="larger">Maturing Feelix</Heading>
      <p>
        In early 2018, MYOB began a major project to shape its future. A key
        part of this was creating a funded design system team to provide tools
        that help teams build consistent, high-quality experiences efficiently.
        With this team’s formation, I had a great opportunity to help shape the
        future vision of the{" "}
        <Links
          href="https://feelix.myob.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Feelix design system
        </Links>
        .
      </p>
      <Rule spacing="medium" inverted />
    </CaseStudiesIntroduction>
    <ColumnLayout
      gridGap={{ lg: "var(--spacing-xx-large)" }}
      gridTemplateColumns={{ lg: "1fr 1.3fr" }}
    >
      <div>
        <Heading variant="smaller" mb="small" mt="clear" color="#ff73df">
          My Feelix involvement
        </Heading>
        <ul>
          <li>Ensuring components follow atomic design principles</li>
          <li>
            Restructuring the design system to use scalable and modular CSS
          </li>
          <li>
            Introducing Responsive web design across the Feelix foundation and
            components
          </li>
          <li>Embedding a baseline grid for vertical rhythm</li>
          <li>Defining a typographic scale with CSS</li>
          <li>
            Designing the Feelix design token structure and naming convention
          </li>
          <li>
            Leading the shift to React layout components instead of using
            Bootstrap classes to create a layout
          </li>
          <li>Driving the design and experience of Feelix page templates</li>
          <li>Expanding usage and technical guidelines</li>
          <li>Automate the UI-Kit to mirror the Feelix Design System</li>
        </ul>
      </div>
      <div>
        <Quote>
          <QuoteImage
            src={EssentialsNew}
            alt="MYOB Incite: New Essentials using Feelix"
            height="236"
          />
          <QuoteInner>
            <Heading variant="small">
              Essentials is fully mobile responsive. Was very cool to see the
              desktop screen resize to a tablet and then again to a mobile.
              Props, MYOB
            </Heading>

            <CaseStudyLinks
              target="_blank"
              rel="noopener noreferrer"
              href="https://digitalfirst.com/myobs-new-ceo-is-building-a-new-myob-and-this-time-its-war/"
            >
              digitalfirst.com - Sholto Macpherson
            </CaseStudyLinks>
          </QuoteInner>
        </Quote>
      </div>
    </ColumnLayout>
  </CaseStudiesCard>
);

export default Introduction;
