import React from "react";
import styled from "styled-components";
import CaseStudiesCard from "../CaseStudiesCard";
import Links from "../../../Links";
import Heading from "../../../Heading";
import AirwallexImage from "../../../../img/airwallex-guidelines.png";
import Box from "../../../Box";

const ProjectsImg = styled.img`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 0 0.8rem #3b3b39;
`;

const Inner = styled.div`
  h1 {
    max-width: 28ch;
  }
  p {
    font-size: var(--font-size-heading-x-small);
    max-width: 62ch;
    &:last-child {
      margin: 0;
    }
  }
  a {
    color: white;
    border-color: rgba(255, 255, 255, 0.2);
    &:hover,
    &:visited {
      color: #ff73df;
    }
  }
`;

const Airwallex = (
  <CaseStudiesCard id="airwallex">
    <Inner>
      <Heading variant="larger">Integrate Guidelines in Figma</Heading>
      <p>
        To maintain consistency across products at Airwallex during the design
        exploration phases, we closely integrated UX Guidelines within Figma,
        ensuring they were always accessible as designers prototyped new
        experiences. This approach helped designers utilise common patterns
        across products and avoid introducing multiple variations of similar
        approaches, aligning design intent with development execution from the
        outset.
      </p>
      <p>
        <Links
          href="https://medium.com/@trilfordm/how-to-create-high-quality-guidelines-for-your-design-system-library-6127b482c1e8"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </Links>{" "}
        about how I created high-quality guidelines.
      </p>
      <Box mt="large">
        <ProjectsImg src={AirwallexImage} />
      </Box>
    </Inner>
  </CaseStudiesCard>
);

export default Airwallex;
