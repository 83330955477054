import React from "react";
import Image from "../../Image";
// import CaseStudyHeading from "./CaseStudiesHeading";
import Rule from "../../Rule";
import Heading from "../../Heading";
// import CaseStudiesParagraph from "./CaseStudiesParagraph";
import CaseStudiesIntroduction from "./CaseStudiesIntroduction";
import CaseStudiesCard from "./CaseStudiesCard";
// import RuralEOFY from "../../../img/casestudy/storytelling/rural-eofy.jpg";
import RuralGSTPeriod from "../../../img/casestudy/storytelling/rural-gst-period.jpg";
import RuralOperationalAdvisory from "../../../img/casestudy/storytelling/rural-operational-advisory.jpg";
import Box from "../../Box";
import Stack from "../../Stack";

const CaseStudiesStoryboard = () => {
  return (
    <>
      {/* <CaseStudyHeading title="Story telling" /> */}

      <CaseStudiesCard>
        <CaseStudiesIntroduction>
          <Heading>Story telling</Heading>
          <p>
            To support the rural accounting initiative and sprint at MYOB, we
            visualised the current user journey to educate the engineering team.
            This helped the team collaborate effectively and contribute to the
            project’s goals. Storyboarding proved invaluable, transforming
            verbatim insights into an engaging, shareable format.
          </p>
          <Rule spacing="medium" inverted />
        </CaseStudiesIntroduction>
        {/* <Heading variant="medium">Rural EOFY</Heading>
        <CaseStudiesParagraph>
          During the creation of this storyboard, I referred to my experience of
          architecting design systems, so I looked to design reusable assets, so
          I could easily create the stories portrayed with the least amount of
          rework or effort.
        </CaseStudiesParagraph>
        <Image dark src={RuralEOFY} alt="Rural storyboard" />
        <Rule spacing="medium" inverted /> */}
        <Stack disableMargin spacing="48px">
          <Box margin="0">
            <Heading variant="medium">E.g: Rural GST period</Heading>
            <Image dark src={RuralGSTPeriod} alt="Rural GST period" />
          </Box>
          <Box margin="0">
            <Heading variant="medium">E.g: Rural operational advisory</Heading>
            <Image
              dark
              src={RuralOperationalAdvisory}
              alt="Rural operational advisory"
            />
          </Box>
        </Stack>
      </CaseStudiesCard>
    </>
  );
};

export default CaseStudiesStoryboard;
