import React from "react";
import styled, { css } from "styled-components";
import Container from "../../Container";
import Rule from "../../Rule";
import Links from "../../Links";
import Heading from "../../Heading";
import SkillsItem from "../Skills/SkillsItem";
import ResumeHeading from "./ResumeHeading";
import DesignSystemsProfile from "../../../img/michaeltrilford.jpeg";
import MobileBanking from "../../../img/mobile-banking.jpg";
import Capture from "../../../img/expense-capture.jpg";
import Airwallex from "../../../img/airwallex-guidelines.png";
import Feelix from "../../../img/feelix.png";
import OnTheWrist from "../../../img/on-the-wrist-resume.jpg";
import CaseStudies from "../../../img/case-studies.png";
import JourneyMap from "../../../img/casestudy/design-sprint/experience-map-exercise.jpg";
import Stack from "../../Stack";
// import Nga from "../../../img/nga.jpg";
import CaseStudiesCard from "../CaseStudies/CaseStudiesCard";
import Served from "../../../video/served.mp4";
// import IOSBeta from "../../../video/iOS14-Beta-App-Lib.mp4";
import ServedPoster from "../../../img/served2.gif";
// import IOSBetaPoster from "../../../img/ios14-app-library.gif";
import IPhoneImage from "../../../img/iphone-device.png";

const VisibleOnDesktop = css`
  display: none;
  @media (min-width: 1250px) {
    display: block;
  }
`;

const VisibleOnTablet = css`
  display: none;
  @media (min-width: 600px) {
    display: block;
  }
`;

const LeftAligned = styled.div`
  text-align: left;
  margin-bottom: var(--spacing-clear);
`;

const CaseStudiesContainer = styled(Container)`
  max-width: 124rem;
  width: 100%;
  padding-top: var(--spacing-clear);
  @media (min-width: 1024px) {
    padding-top: var(--spacing-x-large);
    padding-bottom: var(--spacing-clear);
  }
`;

const ScrollToSection = styled.div`
  margin-bottom: var(--spacing-x-large);
`;

const Grid = styled.div`
  padding: var(--spacing-large);
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-gap: var(--spacing-x-large);
    padding: var(--spacing-large);
    padding-top: var(--spacing-small);
    margin-bottom: var(--spacing-clear);
  }
`;

const Aside = styled.aside`
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
  padding-top: var(--spacing-x-large);
  text-align: left;
  a {
    display: block;
    margin-bottom: var(--spacing-small);
  }
`;

const AsideSticky = styled.div`
  position: sticky;
  top: var(--spacing-x-medium);
`;

const BlogSectionVerticalSpace = styled.section`
  margin-bottom: var(--spacing-clear);
`;

const StyledLinkButton = styled.a`
  color: #ff73df;
  text-decoration: none;
  &:hover,
  &:focus {
    color: #ff89e4;
  }
`;

const ResumeProfileInfo = styled.div`
  @media (min-width: 600px) {
    margin-bottom: var(--flx-spacing-clear);
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    column-gap: var(--spacing-x-large);
  }
`;

const ResumeProfile = styled.div`
  margin: 0;
  margin-bottom: var(--spacing-medium);
  @media (min-width: 1250px) {
    align-items: start;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 4.8rem;
    padding-top: var(--spacing-clear);
    align-items: start;
  }
`;

const ProfileImage = css`
  width: 20rem;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0 0 0 0.8rem #3b3b39;
  img {
    width: 100%;
    height: auto;
    min-height: 20rem;
  }
  ${(props) =>
    props.small &&
    css`
      width: 12rem;
      img {
        min-height: 12rem;
      }
    `}
`;

const PrimaryProfileImage = styled.div`
  ${ProfileImage}
  margin: 0 auto var(--spacing-x-large);
  @media (min-width: 600px) {
    display: none;
  }
  @media (min-width: 1250px) {
    display: block;
  }
`;

const SecondaryProfileImage = styled.div`
  ${ProfileImage}
  display: none;
  @media (min-width: 600px) {
    display: block;
    margin: 0 auto;
    margin-bottom: var(--spacing-medium);
  }
  @media (min-width: 1250px) {
    display: none;
  }
`;

const Contact = styled.div`
  display: flex;
  margin: 0;
  margin-top: var(--spacing-large);
  a {
    color: white;
    border-color: rgba(255, 255, 255, 0.2);
    &:hover {
      color: #ff73df;
    }
  }
  p {
    margin: 0;
  }
  p,
  h5 {
    text-align: center;
  }
  @media (min-width: 600px) {
    display: block;
    margin-top: var(--spacing-clear);
  }
  @media (min-width: 1250px) {
    p,
    h5 {
      text-align: right;
    }
  }
`;

const ContactTitle = styled.h5`
  ${VisibleOnDesktop}
  color: #ff73df;
  margin: var(--spacing-clear);
  margin-bottom: var(--spacing-tiny);
`;

const ContactLocation = styled.div`
  margin-bottom: var(--spacing-clear);
  display: none;
  @media (min-width: 600px) {
    margin-top: var(--spacing-small);
    display: block;
  }
  @media (min-width: 1250px) {
    margin-top: var(--spacing-large);
  }
  p:last-child {
    ${VisibleOnTablet}
  }
`;

const GridContent = styled.div`
  @media (min-width: 1300px) {
    display: grid;
    grid-template-columns: 1fr 340px;
    column-gap: 48px;
    margin-bottom: var(--spacing-clear);
  }
`;

const Introduction = styled.div`
  margin-bottom: 0;
  h3,
  p {
    text-align: center;
  }
  @media (min-width: 600px) {
    h3,
    p {
      text-align: left;
    }
    p {
      max-width: 57ch;
      font-family: var(--font-family);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const PrimaryContent = styled.div`
  margin-bottom: var(--spacing-clear);
`;

const PrimaryCard = styled(CaseStudiesCard)`
  &:last-child {
    margin-bottom: 0;
  }
`;

const SourcedPrimaryCard = styled(CaseStudiesCard)`
  padding-bottom: var(--spacing-medium);
`;

const EducationCard = styled(CaseStudiesCard)`
  margin-bottom: var(--spacing-clear);
  position: sticky;
  top: 48px;
`;

const EducationCardTitle = styled.h2`
  @media (min-width: 1300px) {
    font-size: var(--font-size-heading-medium);
  }
`;

const EducationTitle = styled.h6`
  margin: var(--spacing-clear);
`;

// const EducationFacilitator = styled.p`
//   margin: var(--spacing-clear);
// `;

const EducationDate = styled.p`
  margin-top: var(--spacing-clear);
  margin-bottom: var(--spacing-small);
`;

const SkillGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 36px;
`;

const Sourced = styled.p`
  text-align: center;
  margin: var(--spacing-clear);
`;

const SkillsHeading = styled.h2``;

const SkillsIntroduction = styled.p`
  margin-bottom: var(--spacing-large);
`;

const SkillsList = styled.ul`
  columns: 2;
  column-gap: 4.8rem;
`;

const SkillsInner = styled.div`
  margin-top: var(--spacing-large);
  margin-bottom: var(--spacing-x-large);
  &:last-of-type {
    margin-bottom: var(--spacing-large);
  }
`;

const ProjectsImg = styled.img`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 0 0.8rem #3b3b39;
  margin-bottom: var(--spacing-large);
`;

const ProjectsList = styled.ul`
  margin-bottom: var(--spacing-x-large);
`;

const ProjectsHeadingWrapper = styled.div`
  margin-top: var(--spacing-x-large);
  display: flex;
  align-items: baseline;
  margin-bottom: ${(props) =>
    props.smallMargin ? "var(--spacing-tiny);" : "var(--spacing-medium)"};
`;

const ProjectsHeading = styled.h3`
  margin-bottom: var(--spacing-clear);
  flex: 0 0 auto;
  width: auto;
`;

const ProjectsSubHeading = styled.h3`
  margin-bottom: var(--spacing-clear);
  flex: 0 0 auto;
  width: auto;
  font-weight: normal;
  font-family: var(--font-family);
  color: #bfbfbf;
  span {
    padding: 0 var(--spacing-x-small) 0 var(--spacing-small);
    color: #404040;
  }
`;

const ProjectsParagraph = styled.p`
  margin-bottom: var(--spacing-large);
`;

const ProjectsUList = styled.ul`
  margin-bottom: var(--spacing-large);
`;

const ExperienceHeadingWrapper = styled.div`
  margin-top: var(--spacing-x-large);
  margin-bottom: var(--spacing-medium);
`;

const ExperienceHeading = styled.h3`
  margin-bottom: var(--spacing-clear);
  flex: 0 0 auto;
  width: auto;
`;

const ExperienceSubHeading = styled.h4`
  margin: var(--spacing-clear);
  flex: 0 0 auto;
  width: auto;
  font-weight: normal;
  font-family: var(--font-family);
  color: #bfbfbf;
`;

const VerticalSpace = styled.div`
  height: 2.4rem;
`;

const ServedWrapper = styled.div`
  background: #404040;
  border-radius: 0.8rem;
  box-shadow: 0 0 0 0.8rem #3b3b39;
  display: flex;
  justify-content: center;
  padding: 4.8rem;
  align-items: center;
  margin: 0;

  div {
    display: flex;
    justify-content: center;
    width: 26rem;
    height: 52rem;
    position: relative;
    margin: 0;
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    pointer-events: none;
    z-index: 1;
  }

  video {
    width: 100%;
    height: auto;
    padding: 1.8rem;
    outline: none;
  }
`;

const Resume = () => {
  return (
    <Grid>
      <Aside>
        <AsideSticky>
          <StyledLinkButton href="#introduction">Introduction</StyledLinkButton>
          <StyledLinkButton href="#skills">Skills</StyledLinkButton>
          <StyledLinkButton href="#projects">Projects</StyledLinkButton>
          <StyledLinkButton href="#experience">Experience</StyledLinkButton>
        </AsideSticky>
      </Aside>
      <CaseStudiesContainer large center>
        <LeftAligned>
          <BlogSectionVerticalSpace>
            <ScrollToSection id="introduction">
              <ResumeProfile>
                <PrimaryProfileImage>
                  <img
                    alt="Michael Trilford Profile"
                    src={DesignSystemsProfile}
                  />
                </PrimaryProfileImage>
                <ResumeProfileInfo>
                  <Introduction>
                    <ResumeHeading title="Michael Trilford" />
                    <Heading variant="medium">
                      Lead Product Designer, Design Systems
                    </Heading>
                    <p>
                      I validate hypotheses, uncover insights, and solve
                      business and customer problems. With 16 years of web
                      experience, my skills have naturally expanded to UX,
                      front-end development, and product management.
                    </p>
                    <p>
                      My expertise in design, front-end, and product management
                      has helped shape the strategic direction of the MYOB and
                      Airwallex Design Systems.
                    </p>
                  </Introduction>
                  <Contact>
                    <SecondaryProfileImage small>
                      <img
                        alt="Michael Trilford Profile"
                        src={DesignSystemsProfile}
                      />
                    </SecondaryProfileImage>
                    <ContactTitle>Contact</ContactTitle>
                    <p style={{ marginBottom: 8 }}>
                      <Links
                        rel="noopener noreferrer"
                        href="mailto:trilfordm@gmail.com"
                      >
                        trilfordm@gmail.com
                      </Links>
                    </p>
                    <p style={{ marginBottom: 8 }}>
                      <Links rel="noopener noreferrer" href="tel:+61 434447445">
                        +61434447445
                      </Links>
                    </p>
                    <p style={{ marginBottom: 8 }}>
                      <span role="img" aria-label="PDF">
                        🖨️
                      </span>{" "}
                      <Links
                        href="https://docs.google.com/document/d/1kCfF1tTDmtPWLG6auWC7NZ2wKEyPwdLXKjN7x09-yzk/edit?usp=sharing"
                        target="_blank"
                      >
                        Print resume
                      </Links>
                    </p>
                    <ContactLocation>
                      <ContactTitle>Location</ContactTitle>
                      <p>Melbourne, VIC</p>
                      <p>Australia</p>
                    </ContactLocation>
                  </Contact>
                </ResumeProfileInfo>
              </ResumeProfile>
            </ScrollToSection>
            <GridContent>
              <PrimaryContent>
                <ScrollToSection id="skills">
                  <SourcedPrimaryCard>
                    <Heading>Skills</Heading>
                    <Rule inverted />
                    <SkillsInner>
                      <SkillsHeading>Design systems</SkillsHeading>
                      <SkillsIntroduction>
                        I craft design systems that teams actually want to use,
                        systems that are reusable, composable, and scalable. By
                        working closely with designers and developers, I ensure
                        both Figma and coded components align from the start,
                        embedding tokenisation, accessibility, and flexibility
                        into the foundation. A great design system isn’t just
                        documentation; it’s a living, evolving product that
                        enables teams to build faster while maintaining
                        consistency and quality. My focus is on reducing
                        friction, increasing adoption, and helping teams create
                        with confidence.
                      </SkillsIntroduction>
                      <Heading variant="small">Skill overview</Heading>
                      <SkillsList>
                        <li>
                          Leading and ensuring components follow the atomic
                          design principles.
                        </li>
                        <li>
                          Restructuring design systems to use scalable and
                          modular CSS.
                        </li>
                        <li>
                          Introducing and applying Responsive web design
                          directly into the design systems using CSS.
                        </li>
                        <li>
                          Applying a baseline grid to achieve vertical rhythm
                          within a design system.
                        </li>
                        <li>
                          Incorporating a typographic scale into a design system
                          using CSS.
                        </li>
                        <li>Introducing design tokens to a design system.</li>
                        <li>
                          Designing and building useful layout components.
                        </li>
                        <li>Adding usage guidelines to a design system.</li>
                        <li>
                          Creating a design system UI kit that is 1:1 with the
                          coded source of truth.
                        </li>
                      </SkillsList>
                      <Stack>
                        <Heading variant="small">Related Blog Posts</Heading>
                        <ul>
                          <li>
                            <Links
                              href="https://medium.com/@trilfordm/what-does-scaling-a-design-system-mean-to-you-18d01db92ddd"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              What Does Scaling a Design System Mean to You? •{" "}
                              <i>Mar 2025</i>
                            </Links>
                          </li>

                          <li>
                            <Links
                              href="https://medium.com/@trilfordm/the-future-of-design-systems-with-ai-c04533756d89"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              The Future of Design Systems with AI •{" "}
                              <i>Mar 2025</i>
                            </Links>
                          </li>

                          <li>
                            <Links
                              href="https://medium.com/@trilfordm/enabling-organisations-to-contribute-to-the-design-system-b3dc860b5a04"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Enabling Organisations to Contribute to the Design
                              System • <i>Feb 2025</i>
                            </Links>
                          </li>

                          <li>
                            <Links
                              href="https://medium.com/@trilfordm/crafting-adaptive-layouts-using-responsive-61b1b7b08d32"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Crafting adaptive layouts using a responsive
                              component • <i>Dec 2024</i>
                            </Links>
                          </li>
                          <li>
                            <Links
                              href="https://medium.com/@trilfordm/how-to-create-high-quality-guidelines-for-your-design-system-library-6127b482c1e8"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Creating high quality guidelines • <i>Mar 2023</i>
                            </Links>
                          </li>
                          <li>
                            <Links
                              href="https://uxdesign.cc/improve-relationships-between-product-design-and-development-6ff67d93c987?source=friends_link&sk=7ebf62323b4acfca1076b7cbf461f5d8"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Improve relationships between design &amp;
                              development • <i>May 2020</i>
                            </Links>
                          </li>
                          <li>
                            <Links
                              href="/case-studies#designtheroadmap"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Design System Product Discovery • <i>June 2018</i>
                            </Links>
                          </li>
                        </ul>
                      </Stack>
                    </SkillsInner>
                    <Rule inverted />
                    <SkillsInner>
                      <SkillsHeading>Product Design</SkillsHeading>
                      <SkillsIntroduction>
                        As a Product Designer, I'm very passionate about meeting
                        the business objectives, while successfully balancing
                        this with solving customer problems. With this focus on
                        the business and customer lens, I have been building
                        upon my skills within these areas.
                      </SkillsIntroduction>
                      <SkillGrid>
                        <SkillsItem
                          small
                          title="WEB DESIGN"
                          icon="rgbaIcon"
                          description="I love web design &amp; I have years of responsive web design experience under my belt."
                        />
                        <SkillsItem
                          small
                          title="LEAN UX"
                          icon="uxIcon"
                          description="I have a toolkit of UX techniques that enables me to create, test, discover &amp; solve customer and business problems."
                        />
                        <SkillsItem
                          small
                          title="AGILE"
                          icon="agileIcon"
                          description="I have years of experience working in an agile environment &amp; collaborate well with others to see a project be successful."
                        />
                      </SkillGrid>
                      <Heading variant="small">Skill overview</Heading>
                      <SkillsList>
                        <li>Sketch</li>
                        <li>Creative cloud</li>

                        <li>Using Design systems</li>
                        <li>Creating Design systems</li>

                        <li>Responsive Web Design</li>
                        <li>iOS Native mobile</li>
                        <li>Android Native mobile</li>

                        <li>User discovery scripts</li>
                        <li>User testing scripts</li>
                        <li>User discovery recruitment</li>
                        <li>User testing recruitment</li>
                        <li>User testing facilitation</li>
                        <li>Distilling insights</li>
                        <li>Design sprint facilitation</li>
                        <li>User journey maps</li>

                        <li>Roadmap creation</li>
                        <li>Outcome-based roadmaps (OKR)</li>
                      </SkillsList>
                      <Heading variant="small">Case Study</Heading>
                      <ul>
                        <li>
                          <Links
                            href="/case-studies#designsprints"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Design sprints for SME Rural offering •{" "}
                            <i>July 2017</i>
                          </Links>
                        </li>
                      </ul>
                    </SkillsInner>

                    <Rule inverted />

                    <SkillsInner>
                      <SkillsHeading>Front-end</SkillsHeading>
                      <SkillsIntroduction>
                        To be a product designer that focuses on web
                        experiences, I believe it is essential to understand the
                        medium in which I work. From my experience, I know
                        learning code has contributed to creating a well-crafted
                        experience that is performant and has the level of
                        quality I expect in my day-to-day as a Product Designer.
                      </SkillsIntroduction>
                      <SkillGrid>
                        <SkillsItem
                          small
                          title="HTML"
                          icon="htmlIcon"
                          description="I have great knowledge of HTML, which lets me create well structured &amp; meaningful mark-up."
                        />

                        <SkillsItem
                          small
                          title="CSS"
                          icon="cssIcon"
                          description="I have a deep understanding of CSS, and this allows me to create layouts in the best possible way."
                        />

                        <SkillsItem
                          small
                          title="JQUERY"
                          icon="jqueryIcon"
                          description="Learning jQuery enabled me to craft the interactions of websites I have designed, which is used to bring delight to users."
                        />

                        <SkillsItem
                          small
                          title="SASS"
                          icon="sassIcon"
                          description="I have a great understanding of Sass &amp; I will comfortably design &amp; write my code with this wonderful language."
                        />

                        <SkillsItem
                          small
                          title="XCODE"
                          icon="xcodeIcon"
                          description="Over the past year, I have focused on learning the front-end side of Xcode, which has allowed me to implement my designs."
                        />

                        <SkillsItem
                          small
                          title="RAILS"
                          icon="railsIcon"
                          description="I have experience working with Ruby on Rails to make changes and modifications to the front-end on projects."
                        />

                        <SkillsItem
                          small
                          title="REACT"
                          icon="reactIcon"
                          description="I create reusable components that consist of semantic HTML, visual and responsive styles and required state for components to operate in applications."
                        />
                        <SkillsItem
                          small
                          title="VUE"
                          icon="vueIcon"
                          description="My natural curiosity enables me to easily pick-up other modern Javascript-based frameworks to create reusable components for building web applications."
                        />
                        <SkillsItem
                          small
                          title="WEB COMPONENTS"
                          tooltip="large"
                          icon="webComponentsIcon"
                          description="My experience in creating web components allows me to learn how to structure components in a self-contained approach that can be ported to other JS libraries."
                        />
                      </SkillGrid>
                    </SkillsInner>
                    <Rule inverted />
                    <Sourced>
                      Information sourced from{" "}
                      <Links href="/skills">Skills</Links>
                    </Sourced>
                  </SourcedPrimaryCard>
                </ScrollToSection>

                <ScrollToSection id="projects">
                  <SourcedPrimaryCard>
                    <Heading>Projects</Heading>
                    <Rule inverted />

                    <ProjectsHeadingWrapper smallMargin>
                      <ProjectsHeading>Andromeda Design System</ProjectsHeading>
                      <ProjectsSubHeading>
                        <span>|</span> 2020 - 2023
                      </ProjectsSubHeading>
                    </ProjectsHeadingWrapper>
                    <p>
                      As the Design System Lead at Airwallex, I drove the
                      evolution and maintenance of our in-house Design System,
                      enabling seamless collaboration between design and
                      engineering to ensure consistency, efficiency, and
                      scalability. My approach emphasised empowering teams to
                      contribute effectively to the system, fostering alignment
                      across product teams.
                    </p>

                    <p>
                      To maintain consistency across products during the design
                      exploration phases, we closely integrated UX Guidelines
                      within Figma, ensuring they were always accessible as
                      designers prototyped new experiences. This approach helped
                      designers utilise common patterns across products and
                      avoid introducing multiple variations of similar
                      approaches, aligning design intent with development
                      execution from the outset.
                    </p>

                    <ProjectsUList>
                      <li>
                        <Links
                          href="https://medium.com/@trilfordm/enabling-organisations-to-contribute-to-the-design-system-b3dc860b5a04"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Read more
                        </Links>{" "}
                        about my approach to collaboration.
                      </li>
                      <li>
                        <Links
                          href="https://medium.com/@trilfordm/how-to-create-high-quality-guidelines-for-your-design-system-library-6127b482c1e8"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn more
                        </Links>{" "}
                        about how I created high-quality guidelines.
                      </li>
                    </ProjectsUList>

                    <ProjectsImg src={Airwallex} />

                    <Heading variant="smaller" style={{ marginTop: 0 }}>
                      Key Contributions
                    </Heading>

                    <ul>
                      <li>
                        <strong>Strategic Planning:</strong> Played a pivotal
                        role in strategic decision-making for the Design System,
                        prioritising tasks and aligning with quarterly product
                        goals.
                      </li>

                      <li>
                        <strong>Onboarding:</strong> Streamlined the onboarding
                        process for new Product Designers, providing
                        comprehensive guidance on contribution processes,
                        tooling, and best practices.
                      </li>

                      <li>
                        <strong>Documentation & Guidelines:</strong>{" "}
                        Orchestrated collaborative efforts to enhance our UX
                        documentation. Refocused guidelines to meet evolving
                        product requirements.
                      </li>

                      <li>
                        <strong>Code Reviews:</strong> Ensured high-quality
                        contributions to the Frontend Component Library through
                        detailed reviews of coded components, focusing on design
                        token usage and composability.
                      </li>

                      <li>
                        <strong>Design System Council:</strong> Provided
                        feedback on emerging patterns and components,
                        facilitated updates, and curated feedback for continuous
                        improvement.
                      </li>

                      <li>
                        <strong>Maintenance of Services:</strong> Managed
                        Storybook and aligned Figma UI Kit with the live
                        codebase for consistency across design and development.
                      </li>

                      <li>
                        <strong>Components:</strong> Actively enhanced coded
                        components with responsive updates for optimal
                        performance across devices. Maintained the Figma
                        component UI Library, ensuring accessible properties and
                        easy prototyping with component variants.
                      </li>

                      <li>
                        <strong>Collaboration & Communication:</strong>{" "}
                        Orchestrated effective communication between designers
                        and developers, promoting technical collaboration and
                        efficient development processes.
                      </li>
                    </ul>

                    <p>
                      Throughout my tenure at Airwallex, I've fostered a
                      collaborative Design System environment, delivering
                      robust, scalable, and user-friendly outcomes. I'm eager to
                      leverage my experience to drive design excellence in
                      future opportunities.
                    </p>

                    <VerticalSpace />

                    <ProjectsHeadingWrapper smallMargin>
                      <ProjectsHeading>Feelix Design System</ProjectsHeading>
                      <ProjectsSubHeading>
                        <span>|</span> 2018 - 2020
                      </ProjectsSubHeading>
                    </ProjectsHeadingWrapper>
                    <p>
                      I contributed to the evolution of Feelix by shaping its
                      documentation site, collaborating with engineers to
                      develop key components for the MYOB product experience,
                      crafting design tokens, and enhancing responsive design
                      support through CSS contributions.
                    </p>
                    <ProjectsParagraph>
                      <Links
                        href="/case-studies#designsystems"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Design system case studies • <i>June 2018</i>
                      </Links>
                    </ProjectsParagraph>
                    <ProjectsImg src={Feelix} />

                    <VerticalSpace />

                    <ProjectsHeadingWrapper smallMargin>
                      <ProjectsHeading>
                        Rural Accounting for SME
                      </ProjectsHeading>
                      <ProjectsSubHeading>
                        <span>|</span> 2017
                      </ProjectsSubHeading>
                    </ProjectsHeadingWrapper>
                    <p>
                      In this design sprint, the cross-collaborative group
                      looked to solve known and unknown problems within the
                      Accounting rural space.
                    </p>
                    <ProjectsList>
                      <li>
                        <Links
                          href="/case-studies#designsprints"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Design sprint case study • <i>July 2017</i>
                        </Links>
                      </li>
                      <li>
                        <Links
                          href="/case-studies#storyboards"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Storyboard case study • <i>July 2017</i>
                        </Links>
                      </li>
                    </ProjectsList>
                    <ProjectsImg src={JourneyMap} />

                    <VerticalSpace />

                    <ProjectsHeadingWrapper>
                      <ProjectsHeading>Mobile Banking</ProjectsHeading>
                      <ProjectsSubHeading>
                        <span>|</span> 2017
                      </ProjectsSubHeading>
                    </ProjectsHeadingWrapper>
                    <ProjectsParagraph>
                      When running a small business, there are some tasks a
                      business owner is required to do, which helps with their
                      tax compliance tasks. One of these tasks is reconciling
                      their expenses to their bank transactions that come into
                      their accounting system. To ensure the experience is an
                      enjoyable and most efficient, the experience is designed
                      to be less of a data-entry task, and more of a simple
                      review.
                    </ProjectsParagraph>
                    <ProjectsImg src={MobileBanking} />

                    {/* <ProjectsHeadingWrapper>
                      <ProjectsHeading>iOS14 Beta</ProjectsHeading>
                      <ProjectsSubHeading>
                        <span>|</span> 2020
                      </ProjectsSubHeading>
                    </ProjectsHeadingWrapper>
                    <ProjectsParagraph>
                      My exploration improves the layout and functionality of
                      the home screen widgets and increases the efficiency of
                      accessing the App Library. The hypothesis is that
                      integrating the 'App Library' into the 'Siri search' would
                      be more familiar to a user, and faster to complete the
                      intended task.
                    </ProjectsParagraph>

                    <ServedWrapper>
                      <div>
                        <img
                          width="260"
                          height="520"
                          alt="iPhone app called Served"
                          src={IPhoneImage}
                        />
                        <video
                          width="260"
                          height="520"
                          controls
                          poster={IOSBetaPoster}
                        >
                          <source src={IOSBeta} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </ServedWrapper>

                    <VerticalSpace /> */}

                    <ProjectsHeadingWrapper>
                      <ProjectsHeading>Served</ProjectsHeading>
                      <ProjectsSubHeading>
                        <span>|</span> 2017-2020
                      </ProjectsSubHeading>
                    </ProjectsHeadingWrapper>
                    <ProjectsParagraph>
                      Served is a mobile app that provides a new way of ordering
                      and paying at restaurants. I explored the end-to-end
                      experience of how a user would browse the restaurant menu,
                      right through to settling the bill. With the inconvenience
                      of Covid-19 for restaurants to operate as usual, I have
                      recently explored how to enable this service to allow
                      restaurants to have complete control of home deliveries.
                    </ProjectsParagraph>

                    <ServedWrapper>
                      <div>
                        <img
                          width="260"
                          height="520"
                          alt="iPhone app called Served"
                          src={IPhoneImage}
                        />
                        <video
                          width="260"
                          height="520"
                          controls
                          poster={ServedPoster}
                        >
                          <source src={Served} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </ServedWrapper>

                    <VerticalSpace />

                    <ProjectsHeadingWrapper>
                      <ProjectsHeading>Capture</ProjectsHeading>
                      <ProjectsSubHeading>
                        <span>|</span> 2017
                      </ProjectsSubHeading>
                    </ProjectsHeadingWrapper>
                    <p>
                      During an MYOB Hackday in 2017, I set out to investigate
                      and create a solution for a well-requested feature.
                    </p>
                    <p>
                      The capture experience allows business owners to record
                      and store their business-related expenses in a convenient
                      and accessible digital format. By having this document
                      available in the MYOB, the system enables their accountant
                      to have access to all the information they need for the
                      tax period.
                    </p>
                    <ProjectsParagraph>
                      Fast forward a few years to 2019, MYOB launched an
                      application called MYOB Capture, that's right, Capture.
                      Where I wasn't involved in this project directly, a lot of
                      the original thinking for the experience remains core to
                      the experience, such as capturing an image of the receipt
                      and uploading to an existing storage facility within the
                      MYOB products, called In-tray.
                    </ProjectsParagraph>
                    <ProjectsImg src={Capture} />
                    <ProjectsHeadingWrapper>
                      <ProjectsHeading>OnTheWrist</ProjectsHeading>
                      <ProjectsSubHeading>
                        <span>|</span> 2016
                      </ProjectsSubHeading>
                    </ProjectsHeadingWrapper>
                    <ProjectsParagraph>
                      Receive timely reminders of incoming & outstanding
                      payments, which allows businesses to keep an eye on their
                      business health, without the overhead of being tied to
                      their desk. This ease of mind enables the business owners
                      to focus on more critical areas, which is running and
                      ensuring their business is thriving.
                    </ProjectsParagraph>
                    <ProjectsImg src={OnTheWrist} />
                    <ProjectsHeadingWrapper>
                      <ProjectsHeading>michaeltrilford.co.nz</ProjectsHeading>
                      <ProjectsSubHeading>
                        <span>|</span> 2012-Present
                      </ProjectsSubHeading>
                    </ProjectsHeadingWrapper>
                    <p>
                      This is my responsive portfolio website, which acted as an
                      experimental project to fine-tune my coding skills. My
                      website has been through many iterations, and my latest
                      venture is using React and Styled-components.
                    </p>
                    <ProjectsParagraph>
                      <Links
                        href="/case-studies#technology"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn about technology used on my website
                      </Links>
                    </ProjectsParagraph>
                    <ProjectsImg src={CaseStudies} />

                    {/* <ProjectsHeadingWrapper>
                      <ProjectsHeading>anzacsightsound.org</ProjectsHeading>
                      <ProjectsSubHeading>
                        <span>|</span> 2014-2015
                      </ProjectsSubHeading>
                    </ProjectsHeadingWrapper>
                    <ProjectsParagraph>
                      I lead the design & front-end to build a deserving
                      mobile-first experience, which complements the vibrant
                      content found within the walls at Ngā Taonga Sound &
                      Vision.
                    </ProjectsParagraph>
                    <ProjectsImg src={Nga} /> */}

                    <Rule inverted />
                    <Sourced>
                      Information sourced from <Links href="/">Home</Links> and{" "}
                      <Links href="/case-studies">Case studies</Links>.
                    </Sourced>
                  </SourcedPrimaryCard>
                </ScrollToSection>

                <ScrollToSection id="experience">
                  <PrimaryCard>
                    <Heading>Experience</Heading>
                    <Rule inverted />
                    <ExperienceHeadingWrapper>
                      <ExperienceHeading>
                        Airwallex, Melbourne — Design System Lead
                      </ExperienceHeading>
                      <ExperienceSubHeading>
                        Nov 2020 – Oct 2023 • 3 yrs
                      </ExperienceSubHeading>
                    </ExperienceHeadingWrapper>

                    <ExperienceHeadingWrapper>
                      <ExperienceHeading>
                        MYOB, Melbourne — Senior Design Technologist
                      </ExperienceHeading>
                      <ExperienceSubHeading>
                        Mar 2018 – Nov 2020 • 2 yrs 9 mos
                      </ExperienceSubHeading>
                    </ExperienceHeadingWrapper>

                    <ExperienceHeadingWrapper>
                      <ExperienceHeading>
                        MYOB, Melbourne — Senior Product Designer
                      </ExperienceHeading>
                      <ExperienceSubHeading>
                        Feb 2018 – Mar 2018 • 2 mos
                      </ExperienceSubHeading>
                    </ExperienceHeadingWrapper>

                    <ExperienceHeadingWrapper>
                      <ExperienceHeading>
                        MYOB, Melbourne — Product Designer
                      </ExperienceHeading>
                      <ExperienceSubHeading>
                        Oct 2015 – Feb 2018 • 2 yrs 5 mos
                      </ExperienceSubHeading>
                    </ExperienceHeadingWrapper>

                    <ExperienceHeadingWrapper>
                      <ExperienceHeading>
                        Boost, Wellington — Front-End Designer
                      </ExperienceHeading>
                      <ExperienceSubHeading>
                        Aug 2012 – Oct 2015 • 3 yrs 3 mos
                      </ExperienceSubHeading>
                    </ExperienceHeadingWrapper>

                    <ExperienceHeadingWrapper>
                      <ExperienceHeading>
                        Boost, Wellington — Web Designer
                      </ExperienceHeading>
                      <ExperienceSubHeading>
                        Nov 2006 – Aug 2012 • 5 yrs 10 mos
                      </ExperienceSubHeading>
                    </ExperienceHeadingWrapper>
                  </PrimaryCard>
                </ScrollToSection>
              </PrimaryContent>

              <ScrollToSection id="workshops">
                <EducationCard>
                  <EducationCardTitle>Career</EducationCardTitle>
                  <EducationTitle>
                    Boost
                    <span
                      style={{
                        fontWeight: "normal",
                        opacity: 0.5,
                        fontFamily: "var(--font-family)",
                        margin: "0 12px",
                      }}
                    >
                      | 2006–2015
                    </span>
                  </EducationTitle>

                  <EducationTitle>
                    MYOB
                    <span
                      style={{
                        fontWeight: "normal",
                        opacity: 0.5,
                        fontFamily: "var(--font-family)",
                        margin: "0 12px",
                      }}
                    >
                      | 2015 – 2020
                    </span>
                  </EducationTitle>

                  <EducationTitle>
                    Airwallex
                    <span
                      style={{
                        fontWeight: "normal",
                        opacity: 0.5,
                        fontFamily: "var(--font-family)",
                        margin: "0 12px",
                      }}
                    >
                      | 2020 – 2023
                    </span>
                  </EducationTitle>

                  <EducationTitle>
                    Career Break
                    <span
                      style={{
                        fontWeight: "normal",
                        opacity: 0.5,
                        fontFamily: "var(--font-family)",
                        margin: "0 12px",
                      }}
                    >
                      | 2023 – 2024
                    </span>
                  </EducationTitle>
                  <VerticalSpace />
                  <EducationCardTitle>Education</EducationCardTitle>
                  <EducationTitle>Bachelor of Design</EducationTitle>
                  <EducationDate>Massey • 2002 – 2006</EducationDate>
                  <VerticalSpace />
                  <EducationCardTitle>Workshops</EducationCardTitle>
                  <EducationTitle>Lean UX</EducationTitle>
                  <EducationDate>Jeff Gothelf • 2013</EducationDate>
                  <EducationTitle>Atomic Design</EducationTitle>
                  <EducationDate>Brad Frost • 2015</EducationDate>
                  <EducationTitle>Mapping experiences</EducationTitle>
                  <EducationDate>James Kalbach • 2016</EducationDate>
                </EducationCard>
              </ScrollToSection>
            </GridContent>
          </BlogSectionVerticalSpace>
        </LeftAligned>
      </CaseStudiesContainer>
    </Grid>
  );
};

export default Resume;
